import { Layout } from 'components';
import { graphql } from 'gatsby';
import React from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Slice } from '../slices/Slice';
import * as styles from './teamPage.module.scss';

const inheritedClassnames = {
  header: styles.header,
  headerBody: styles.headerBody,
  wrapper: styles.wrapper,
};

const TeamPage = ({ location, data: staticData }) => {
  const data = staticData;
  const {
    prismicTeam: { data: pageData },
  } = data;
  const { tagline, title, content, body: sliceData } = pageData;
  const headerProps = { title, tagline, content };
  return (
    <Layout
      location={location}
      pageData={pageData}
      headerProps={headerProps}
      bodyClassName={styles.teamPage}
      inheritedClassnames={inheritedClassnames}
    >
      {sliceData.map(slice => (
        <Slice key={slice.id} data={slice} />
      ))}
    </Layout>
  );
};

export default withPrismicPreview(TeamPage);

export const pageQuery = graphql`
  {
    prismicTeam {
      uid
      prismicId
      _previewable
      data {
        tagline {
          text
        }
        title {
          text
        }
        content {
          html
        }
        body {
          ...TeamSectionTeamFragment
          ...CtaTeamFragment
        }
      }
    }
  }
`;
